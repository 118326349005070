<template>
  <router-link
    :to="{ name: 'LiffNewsShow', params: { id: news.id } }"
    v-slot="{ navigate }"
    custom
  >
    <div class="s-news-item" @click="navigate">
      <div class="s-news-item-image">
        <img :src="news.image_url">
      </div>
      <div class="s-news-item-info">
        <div class="s-space-y-2 s-mr-auto">
          <p class="s-text-gray-darker s-font-semibold">{{ news.title }}</p>
          <p class="s-text-gray s-text-xs s-whitespace-nowrap">{{ datetime }}</p>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
  import moment from 'moment'

  export default {
    props: {
      news: {
        type: Object,
        required: true,
      },
    },
    computed: {
      datetime () {
        return moment(this.news.created_at).format('YYYY-MM-DD HH:mm')
      },
    },
  }
</script>

<style scoped>
  .s-news-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: var(--s-white);
  }

  .s-news-item-image {
    flex-shrink: 0;
    padding: 0.5rem;
    font-size: 0;
    width: 5rem;
    height: 5rem;
  }

  .s-news-item-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .s-news-item-info {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    padding: 1rem;
  }
</style>
